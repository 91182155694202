import { notify } from "@kyvg/vue3-notification";
import router from '../router'
import NotifyConfig from '../conf/notify'
import account from './account'
import security from './security-code'
import axios from "axios";

const STORAGE_KEY = 'id_token';
const LAST_LOGIN_STORAGE_KEY = 'last_login';
const CREDENTIAL_CONFIRM_TIMEOUT_IN_MINUTES = 5;

export default {

    STORAGE_KEY,
    LAST_LOGIN_STORAGE_KEY,

    user: {
      authenticated: false
    },
  
    login : function(credential) {
        return new Promise((resolve, reject) => {
        axios.post(import.meta.env.VITE_APP_API_ENDPOINT+'/api/auth/login', credential)
        .then((response) => {
            localStorage.setItem(STORAGE_KEY, response.data.token)
            localStorage.setItem(LAST_LOGIN_STORAGE_KEY, Date.now())
            this.user.authenticated = true
            resolve();
        }).catch((error) => {
            if (error.response) {
            notify({...NotifyConfig, ...{
                title: 'Login Error',
              }
            });
          }
          reject()
        })
      });
    },
  
    logout() {
        localStorage.removeItem(STORAGE_KEY)
        account.destroye()
        security.destroye()
        this.user.authenticated = false
        router.push({ name: 'login' })
    },
  
    checkAuth() {
      var jwt = localStorage.getItem(STORAGE_KEY)
      if(jwt) {
        this.user.authenticated = true
      }
      else {
        this.user.authenticated = false      
      }
    },

    haveRecentlyIdentified() {
      var lastLoginTimeStamp = localStorage.getItem(LAST_LOGIN_STORAGE_KEY);
      const timeout = CREDENTIAL_CONFIRM_TIMEOUT_IN_MINUTES * 60 * 1000 ;
      if (lastLoginTimeStamp && (Date.now() - parseInt(lastLoginTimeStamp)) < timeout) {
        return true;
      }
      return false;
    },

    getAuthHeader() {
      return {
        'Authorization': 'Bearer ' + localStorage.getItem(STORAGE_KEY)
      }
    }
  }