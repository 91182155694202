// https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
import { createWebHistory, createRouter } from 'vue-router'
import auth from './middleware/auth'
import securityCode from './middleware/securityCode'

import Login from './components/Login.vue'
import SecurityCodeConfig from './components/SecurityCodeConfig.vue'
import Tours from './components/Tours.vue'
import Tour from './components/Tour.vue'
import Registration from './components/Registration.vue'


export const router = new createRouter({
    history: createWebHistory(),
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes: [
      { path: '/login',  name: 'login', component: Login },
      { path: '/security-code', name: 'security-code', component: SecurityCodeConfig, meta: { middleware: [auth] }  },
      { path: '/', name: 'home', component: Tours, meta: { middleware: [auth, securityCode] } },
      { path: '/t/:tour', name: 'tour', component: Tour, meta: { middleware: [auth, securityCode] } },
      { path: '/t/:tour/r/:registration', name: 'editRegistration', component: Registration, meta: { middleware: [auth, securityCode] }},
      { path: '/t/:tour/r', name: 'createRegistration', component: Registration, meta: { middleware: [auth, securityCode] }},
  
      // otherwise redirect to home
      { path: '/:catchAll(.*)', redirect: '/' }
    ]
});
//
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Than run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
      return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;