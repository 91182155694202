<template>
    <div>
        <div v-if="loadingRegistration">
            <full-screen>
                <template v-slot:header>
                    <router-link :to="{ name: 'home' }" class="text-blue-500 mt-3 inline-block">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            class="w-8 icon inline-block text-blue-500 fill-current"
                        >
                            <path
                                d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
                            />
                        </svg>
                        {{ $t('step-1.header.back') }}
                    </router-link>
                </template>
                <div class="text-center pt-24">
                    <spinner class="w-16 h-16 text-gray-400 stroke-current mx-auto"></spinner>
                    <span class="text-gray-400 font-extrabold text-2xl">Loading....</span>
                </div>
            </full-screen>
        </div>
        <div v-else>
            <registration-create
                v-if="currentStep === 0"
                :tour="tour"
                :registration="registration"
                @new-registration="updateRegistration($event, 1)"
            ></registration-create>
            <registration-step-1
                v-if="currentStep === 1"
                :tour="tour"
                :registration="registration"
                v-on:back="goToStep(0)"
                @step-1-validated="updateRegistration($event, 2)"
            ></registration-step-1>
            <registration-step-2
                v-if="currentStep === 2"
                :tour="tour"
                :registration="registration"
                v-on:back="goToStep(1)"
                v-on:proofOfIdentityAdded="setProofOfIdentity($event)"
            ></registration-step-2>
            <registration-step-3
                v-if="currentStep === 3"
                :tour="tour"
                :registration="registration"
                v-on:back="goToStep(2)"
                v-on:signatureAdded="setSignature($event)"
            ></registration-step-3>
            <transition>
                <registration-step-4
                    v-if="currentStep === 4"
                    :tour="tour"
                    :registration="registration"
                    :loading="saveInProgress"
                    v-on:back="goToStep(3)"
                    v-on:go-to-step="goToStep($event)"
                    v-on:registrationConfirmed="saveRegistration()"
                ></registration-step-4>
            </transition>
            <transition
                name="custom-classes-transition"
                enter-active-class="animated bounceIn"
                leave-active-class="animated bounceOut"
            >
                <registration-confirmation-modal
                    v-show="isValidated"
                    :tour="tour"
                    :registration="registration"
                />
            </transition>
        </div>
    </div>
</template>
<script>
import tour from '../api/tour'
import APIProvider from '../api/registration'
import AxiosErrorHandler from '../helpers/error'

import FullScreen from "@/components/FullScreen.vue";
import RegistrationCreate from '@/components//RegistrationCreate.vue'
import RegistrationStep1 from '@/components//RegistrationStep1.vue'
import RegistrationStep2 from '@/components//RegistrationStep2.vue'
import RegistrationStep3 from '@/components//RegistrationStep3.vue'
import RegistrationStep4 from '@/components//RegistrationStep4.vue'
import RegistrationConfirmationModal from '@/components//RegistrationConfirmationModal.vue'
import Spinner from '@/components//Spinner.vue'

export default {
    components: {
        FullScreen,
        RegistrationCreate,
        RegistrationStep1,
        RegistrationStep2,
        RegistrationStep3,
        RegistrationStep4,
        RegistrationConfirmationModal,
        Spinner,
    },
    data() {
        return {
            loadingRegistration: true,
            saveInProgress: false,
            currentStep: 1,
            tour,
            registration: {},
            isValidated: false
        }
    },
    computed: {
      signatureField() {
        return this.tour.fields.find(field => field.type === 'signature')
      }
    },
    methods: {
        updateRegistration: function (pros, nextStrep) {
            this.registration = { ...this.registration, ...pros };
            this.currentStep = nextStrep
        },
        setProofOfIdentity: function (string) {
            if (!this.registration.proofOfIdentities) {
                this.registration.proofOfIdentities = []
            }
            if (this.registration.proofOfIdentities.length > 0) {
                this.registration.proofOfIdentities.splice(0, 1);
            }
            this.registration.proofOfIdentities.push({
                image: string
            });
            if (this.signatureField) {
                this.currentStep = 3
            } else {
                this.currentStep = 4
            }
        },
        setSignature: function (string) {
            if (! this.signatureField) {
              alert('Signature field not found')
              return
            }
            this.registration[this.signatureField?.key] = string;
            this.currentStep = 4
        },
        goToStep: function (step) {
            this.currentStep = step;
        },
        saveRegistration: function () {
            let vm = this
            const api = new APIProvider(this.tour)
            vm.saveInProgress = true
            if (this.$route.params.registration) {
                api.update(this.$route.params.registration, { ...this.registration, ...{ 'lang': this.$i18n.locale } })
                    .then((response) => {
                        vm.isValidated = true
                    }).catch((error) => {
                        AxiosErrorHandler(error);
                    }).finally(() => {
                        vm.saveInProgress = false
                    })
            } else {
                api.create({ ...this.registration, ...{ 'lang': this.$i18n.locale } })
                    .then((response) => {
                        vm.isValidated = true
                    }).catch((error) => {
                        AxiosErrorHandler(error);
                    }).finally(() => {
                        vm.saveInProgress = false
                    })
            }
        }
    },
    mounted() {
        var vm = this;
        tour.get(this.$route.params.tour, this.$i18n.locale)
            .then((tour) => {
                this.tour = tour;

                if (!this.$route.params.registration) {
                    this.currentStep = 0;
                    this.loadingRegistration = false;
                    return
                }

                const api = new APIProvider(this.tour);
                api.get(this.$route.params.registration)
                    .then((response) => {
                        this.registration = { ...this.registration, ...response.data };
                        this.loadingRegistration = false;
                    })
                    .catch((error) => {
                        AxiosErrorHandler(error);
                    });
            })
            .catch((error) => {
                AxiosErrorHandler(error);
            });
    }
}
</script>