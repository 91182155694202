<template>
  <div
    class="flex flex-no-wrap  items-center justify-between py-2 border-b border-gray-300"
  >
    <div class="text-gray-500 mr-auto">
      {{ $t("registration.fields." + name) }}
    </div>
    <div class="text-black">
      {{ valueFormated }}
    </div>
    <div v-if="editable">
      <div class="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          class="w-4 ml-2 text-blue-500  fill-current"
          v-on:click.prevent="showForm = true"
        >
          <path
            d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z"
          />
        </svg>
        <div
          class="absolute  bottom-0 right-0 border-gray-100 rounded bg-white shadow-lg font-normal z-50 p-3 w-64"
          v-if="showForm"
        >
          <svg
            v-on:click.prevent="discardForm"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-6 fill-current text-blue-500 float-right"
          >
            <path
              fill-rule="evenodd"
              d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
            />
          </svg>
          <Form v-slot="{ handleSubmit, invalid }">
            <form
              class="w-full"
              id="createRegistrationForm"
              v-on:submit.prevent="handleSubmit(updateValue)"
            >
              <select-input
                v-if="type == 'select'"
                :label="$t('registration.fields.' + name)"
                class="mt-1"
                :help="help"
                v-model="v"
                :rules="rules"
                :options="options"
                :value="v"
                :vid="name"
              >
              </select-input>
              <text-input
                v-else-if="type == 'number'"
                :label="$t('registration.fields.' + name)"
                :type="type"
                :rules="rules"
                class="mt-1"
                v-model="v"
                :value="v"
                :vid="name"
                @input="v = parseInt($event)"
              ></text-input>
              <text-input
                v-else
                :label="$t('registration.fields.' + name)"
                :type="type"
                :rules="rules"
                class="mt-1"
                v-model="v"
                :value="v"
                :vid="name"
                @input="v = $event"
              ></text-input>
              <button
                type="submit"
                :disabled="invalid"
                :class="{ 'opacity-50 cursor-not-allowed': invalid }"
                class="text border border-blue-500 text-blue-500  rounded mt-2 p-2 w-full"
              >
                {{ $t("update") }}
              </button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import SelectInput from "@/components/SelectInput.vue";
import TextInput from "@/components/TextInput.vue";

import { Form } from "vee-validate";
import dayjs from "dayjs";

export default {
  components: {
    SelectInput,
    TextInput,
    Form,
  },
  props: {
    name: {
      required: true,
    },
    value: {
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      default: undefined,
    },
    rules: {
      default: undefined,
    },
    options: {
      default: undefined,
    },
    help: {
      default: undefined,
    },
  },
  data() {
    return {
      showForm: false,
      v: null,
    };
  },
  watch: {
    value(v) {
      this.v = v;
    },
  },
  computed: {
    valueFormated() {
      if (this.type == "date") {
        dayjs.locale(this.$i18n.locale);
        return dayjs(this.value).format("LL");
      }
      if (this.type == "select" && this.options && this.options.length > 0) {
        let option = this.options.find((option) => {
          return option.value === this.value;
        });
        if (option) {
          return option.text;
        }
      }
      return this.value;
    },
  },
  methods: {
    updateValue() {
      this.$emit("registration-input", this.v);
      this.showForm = false;
    },
    discardForm() {
      this.v = this.value;
      this.showForm = false;
    },
  },
  mounted() {
    this.v = this.value;
  },
};
</script>
