<template>
    <form v-on:submit.prevent="$emit('form:submit', credentials) ">
        <div class="mb-4">
            <label class="block text-grey-darker text-sm font-bold mb-2" for="username">
                Email address
            </label>
            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="email"
                inputmode="email"
                placeholder="you@amersports.com"
                v-model="credentials.email">
        </div>
        <div class="mb-6">
            <label class="block text-grey-darker text-sm font-bold mb-2" for="password">
                Password
            </label>
            <input class="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3 leading-tight focus:outline-none focus:shadow-outline"
                id="password" type="password" placeholder="******************" v-model="credentials.password">
        </div>
        <div class="flex items-center justify-between">
            <button class="bg-blue-500 w-full hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                login
            </button>
        </div>
    </form>
</template>
<script>
export default {
    data() {
        return {
            credentials : {
                email : '',
                password : ''
            },
        }
    },
}
</script>