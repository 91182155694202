
import auth from "../auth";
import AxiosErrorHandler from "../helpers/error";
import axios from "axios";

export default {
  getAll() {
    return axios.get(import.meta.env.VITE_APP_API_ENDPOINT + "/api/event", {
      headers: auth.getAuthHeader(),
    });
  },
  get(id, locale) {
    locale = locale || "en";
    return new Promise((resolve, reject) => {
      axios
        .get(import.meta.env.VITE_APP_API_ENDPOINT + "/api/event/" + id, {
          headers: auth.getAuthHeader(),
        })
        .then((response) => {
          var tour = response.data;
          axios
            .get(
              tour.registration.fields + '?locale=' + locale,
              {
                headers: auth.getAuthHeader(),
              }
            )
            .then((response) => {
              resolve({ ...tour, ...{ fields: response.data } });
            })
            .catch(() => {
              resolve(tour);
            });
        })
        .catch((error) => {
          reject(error);
          AxiosErrorHandler(error);
        });
    });
  },
};
