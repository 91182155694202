<template>
    <form class="flex flex-col max-w-2xl mx-auto" v-on:submit.prevent="searchEmail">
        <search-input
            @input="email = $event.target.value"
          type="email"
          inputmode="email"
          :placeholder="$t('tour.search-registration.placeholder')"
        />

        <button type="submit" class=" rounded-lg  text-2xl bg-blue-500 text-white p-4 mt-4" :class="{'opacity-50 cursor-not-allowed' : !disableForm}"
            :disabled="!disableForm">{{ $t("tour.search-registration.button") }}</button>
    </form>
</template>
<script>

import SearchInput from '@/components/SearchInput.vue'
import isValidEmail from '../helpers/email'

export default {
    components: {
        SearchInput,
    },
    props: {
        disabled: {
            default: true
        }
    },
    data() {
        return {
            email: '',
        }
    },
    computed : {
        disableForm () {
          return !this.disabled && isValidEmail(this.email);
        },
        isValidEmail () {
            return isValidEmail(this.email);
        }
    },
    methods : {
        searchEmail() {
            this.$emit('submitSearch', this.email);
        },
    }
}
</script>