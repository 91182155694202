<template>
    <Field :name="vid"  :rules="rules" v-slot="{ field, errors }">
        <label class="block tracking-wide text-gray-700 text-xs mb-1 flex relative" for="grid-first-name">
            <span class="font-bold uppercase">{{ label }}</span>
            <button v-if="help" v-on:click.prevent="showHelp = true" class="ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 icon-blue-500">
                    <path class="primary" d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20z"/>
                    <path class="secondary" d="M12 19.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.5a1 1 0 0 1-2 0v-1.41a1 1 0 0 1 .55-.9L14 10.5C14.64 10.08 15 9.53 15 9c0-1.03-1.3-2-3-2-1.35 0-2.49.62-2.87 1.43a1 1 0 0 1-1.8-.86C8.05 6.01 9.92 5 12 5c2.7 0 5 1.72 5 4 0 1.3-.76 2.46-2.05 3.24L13 13.2V14z"/>
                </svg>
            </button>
            <div class="absolute inset-x-0 bottom-0 bg-gray-800 shadow-md font-normal z-50 p-2 text-white text-xs" v-if="help && showHelp">
                <svg v-on:click.prevent="showHelp = false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 fill-current text-white float-right">
                    <path fill-rule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"/>
                </svg>
                <div v-html="help"></div>
            </div>
        </label>
        <div class="relative">
            <select class="block appearance-none w-full bg-gray-200 border border-gray-200 
             text-gray-700 text-lg py-2 px-4 pr-8 
             rounded leading-normal 
             focus:outline-none focus:bg-white focus:border-gray-300 "
                v-bind:value="value"
                :placeholder="placeholder" 
                autocomplete="off" spellcheck="false"
                @change="$emit('input', $event.target.value)"
                    v-bind="field"
            >
                <option v-for="option in options" :key="option.value" :value="option.value" :disabled="option.disabled">{{ option.text }}</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
            </div>
        </div>
        <span class="text-red-500 text-xs">{{ errors[0] }}</span>
  </Field>
</template>

<script>
import { Field } from 'vee-validate';

export default {
    components: {
        Field
    },
    props : {
        label: {
            type: String,
            required: true
        },
        placeholder : {
            type: String,
        },
        type : {
            type: String,
            default: 'text'
        },
        value: {
            type: String,
            default: ''
        },
        vid: {
            type: String,
            default: ''
        },
        rules: {
            type: [String, Object],
            default: ''
        },
        options: {
            type: Array,
            required: true
        },
        help: {
            type: String,
            default: undefined
        }
    },
    data () {
        return {
            showHelp: false
        }
    }
}
</script>

<style>

</style>