<template>
    <div>
        <div class="relative">
            <input 
            v-model=search
            :placeholder=placeholder
            :type=type
            :inputmode="inputmode"
            class="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 pr-4 pl-10 block w-full appearance-none leading-normal ds-input text-xl" 
            autocomplete="off"
            spellcheck="false"
            role="combobox"
            aria-autocomplete="list" 
            aria-expanded="false"
            aria-label="search input"
            aria-owns="algolia-autocomplete-listbox-0"
            dir="auto" 
            style="position: relative;
            vertical-align: top;">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
                <svg class="fill-current pointer-events-none text-gray-600 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path></svg>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props : {
        value : {
            default: '',
        },
        placeholder: {
            default : 'Search ...',
        },
        type: {
            default: 'text',
        },
        inputmode: {
            default: 'search',
        },
    },
    data() {
        return {
            search : '',
        }
    },
    watch: {
        value : function (value) {
            this.search = value;
        },
        search : function (value) {
          this.$emit('search:input', value)
        }
    },
    mounted() {
        this.search = this.value;
    },
}
</script>