
import auth from '../auth'
import axios from "axios";

class APIProvider {
    constructor(tour) {
        this.tour = tour;
    }

    searchByEmail(email) {
        return axios.get(import.meta.env.VITE_APP_API_ENDPOINT+'/api/'+this.tour.slug+'/registration', {
            params: {
                q: email,
            },
            headers: auth.getAuthHeader()
        });
    }

    get(id) {
        return axios.get(import.meta.env.VITE_APP_API_ENDPOINT+'/api/'+this.tour.slug+'/registration/'+id, {
            headers: auth.getAuthHeader()
        });
    }

    create(data) {
        return axios.post(import.meta.env.VITE_APP_API_ENDPOINT+'/api/'+this.tour.slug+'/registration',
            data,
            { 
                headers: auth.getAuthHeader(),
            }
        );
    }

    update(id, data) {
        return axios.put(import.meta.env.VITE_APP_API_ENDPOINT+'/api/'+this.tour.slug+'/registration/'+id,
            data,
            { 
                headers: auth.getAuthHeader(),
            }
        );
    }
}
export default APIProvider;