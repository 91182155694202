<template>
  <full-screen>
    <template v-slot:header>
      <div class="flex justify-between">
        <button
          class="text-blue-500 mt-3 inline-block"
          v-on:click.prevent="checkUserAndRedirectToHome()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="ml-4 w-4 icon inline-block icon-blue-500 fill-current"
          >
            <path
              class="primary"
              d="M5 10h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm6 6.73V18a1 1 0 0 0 2 0v-1.27a2 2 0 1 0-2 0z"
            />
            <path
              class="secondary"
              d="M12 19a1 1 0 0 0 1-1v-1.27A2 2 0 0 0 12 13v-3h3V7a3 3 0 0 0-6 0v3H7V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-7v-3z"
            />
          </svg>
          {{ $t("tour.header.change-tour") }}
        </button>
        <button
          class="text-blue-500 mt-3 inline-block"
          v-on:click.prevent="editSettings()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="mr-4 w-4 icon inline-block icon-blue-500 fill-current"
          >
            <path
              class="primary"
              d="M6.8 3.45c.87-.52 1.82-.92 2.83-1.17a2.5 2.5 0 0 0 4.74 0c1.01.25 1.96.65 2.82 1.17a2.5 2.5 0 0 0 3.36 3.36c.52.86.92 1.8 1.17 2.82a2.5 2.5 0 0 0 0 4.74c-.25 1.01-.65 1.96-1.17 2.82a2.5 2.5 0 0 0-3.36 3.36c-.86.52-1.8.92-2.82 1.17a2.5 2.5 0 0 0-4.74 0c-1.01-.25-1.96-.65-2.82-1.17a2.5 2.5 0 0 0-3.36-3.36 9.94 9.94 0 0 1-1.17-2.82 2.5 2.5 0 0 0 0-4.74c.25-1.01.65-1.96 1.17-2.82a2.5 2.5 0 0 0 3.36-3.36zM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
            />
            <circle cx="12" cy="12" r="2" class="secondary" />
          </svg>
        </button>
      </div>
    </template>

    <div v-if="isLoading" class="text-center pt-24">
      <spinner class="w-16 h-16 text-gray-400 stroke-current mx-auto"></spinner>
      <span class="text-gray-400 font-extrabold text-2xl">Loading....</span>
    </div>
    <div v-else>
      <div class="text-center text-4xl font-extrabold mt-6 mb-4">
        {{ tour.name }}
      </div>

      <locale-changer></locale-changer>

      <div class="text-xl text-gray-600 mt-8 mb-4">
        {{ $t("tour.subtitle") }}
      </div>

      <search-registration
        :disabled="disableSearch"
        v-on:submitSearch="searchRegistrations($event)"
        class="mb-16"
      >
      </search-registration>
    </div>

    <template v-slot:extra-footer>
      <modal
        :show="searchHasBeenProcessed && registrations.length === 0"
        v-on:close="resertSearch()"
      >
        <div class="text-center mt-3 text-gray-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-32 mb-4 mx-auto icon"
          >
            <path
              class="primary"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              class="secondary"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          <span class="block">{{ $t("tour.no-result") }}</span>
          <div class="p-8">
            <router-link
              :to="{
                name: 'createRegistration',
                params: { tour: tour.slug },
                query: { email: email },
              }"
              class="rounded-lg  w-1/2 text-2xl bg-blue-500 text-white p-4 mt-4 mb-6"
            >
              {{ $t("tour.start-onfield-registration") }}
            </router-link>
          </div>
          <!--
                    <span class="block mt-3 mb-5">{{ $t('tour.please-register')}} </span>
                    <div class="flex justify-center mx-auto max-w-2xl" v-if="getBrand()">
                        <div class="w-1/2 text-center" 
                            v-if="getBrand().short === 'ato' || getBrand().short === 'ato-sal' ">
                            <qr-code class="w-40  mx-auto" :text="$t('tour.qr-code.ato')"></qr-code>
                            <span class="text-gray-500">Atomic</span>
                        </div>
                        <div class="w-1/2 text-center" 
                            v-if="getBrand().short === 'sal' || getBrand().short === 'ato-sal' ">
                            <qr-code class="w-40  mx-auto" :text="$t('tour.qr-code.sal')"></qr-code>
                            <span class="text-gray-500">Salomon</span>
                        </div>
                    </div>
                    -->
        </div>
      </modal>

      <modal
        :show="registrations && registrations.length > 0"
        v-on:close="resertSearch()"
      >
        <div class="w-3/4 self-center overflow-y-scroll px-2">
          <div
            v-for="registration in registrations"
            :key="registration.id"
            class="border border-gray-300 rounded-lg bg-gray-100 p-3 text-lg items-center mt-4"
          >
            <router-link
              v-if="isValid(registration)"
              :to="{
                name: 'editRegistration',
                params: { tour: tour.slug, registration: registration.id },
              }"
              class="items-center flex justify-between content-center"
            >
              <span>
                {{ registration.firstname }}
                <strong>{{ registration.lastname }}</strong><br>
                <small class="text-gray-600">{{ registration.email }}</small>
              </span>
              <button
                type="button"
                class="rounded-lg flex items-center text-lg bg-blue-500 text-white py-2 pl-4 pr-2"
              >
                <span>{{ $t('tour.finalize-registration') }}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-8 fill-current text-white"
                >
                  <path
                    d="M10.3 8.7a1 1 0 0 1 1.4-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.4-1.4l3.29-3.3-3.3-3.3z"
                  />
                </svg>
              </button>
              
            </router-link>
            <div
              v-else
              class="block flex justify-between content-center opacity-50"
            >
              <span>
                {{ registration.firstname }}
                <strong>{{ registration.lastname }}</strong>
              </span>
              <span class="text-sm">
                {{ $t("tour.already-validated") }}
              </span>
            </div>
          </div>

          <div class="p-8 mt-8  text-center border border-gray-500 rounded-lg border-dashed">
            <h3 class="font-extrabold">{{ $t('tour.not-yet-registered') }}</h3>
            <p class=" text-gray-600 mb-8">
              {{ $t('tour.you-can-register-using-the-button-below') }}
            </p>
            <router-link
              :to="{
                name: 'createRegistration',
                params: { tour: tour.slug },
                query: { email: email },
              }"
              class="rounded-lg w-1/2 text-lg bg-white text-blue-500 border border-blue-500 p-3 mt-4 mb-6"
            >
              {{ $t("tour.start-onfield-registration") }}
            </router-link>
          </div>
        </div>
      </modal>

      <modal :show="showLoginModal" v-on:close="showLoginModal = false">
        <div class="w-1/3 self-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-24 mx-auto icon-blue-500"
          >
            <path
              class="primary"
              d="M5 10h14a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2zm6 6.73V18a1 1 0 0 0 2 0v-1.27a2 2 0 1 0-2 0z"
            />
            <path
              class="secondary"
              d="M12 19a1 1 0 0 0 1-1v-1.27A2 2 0 0 0 12 13v-3h3V7a3 3 0 0 0-6 0v3H7V7a5 5 0 1 1 10 0v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-7v-3z"
            />
          </svg>
          <div>
            <div class="text-4xl text-center font-extrabold mt-6">
              Staff Login
            </div>
          </div>
          <login-form @form:submit="checkLogin($event)"></login-form>
        </div>
      </modal>

      <modal :show="showSettingsModal" v-on:close="showSettingsModal = false">
        <div class="w-full max-w-xl self-center">
          <div class="text-4xl text-center font-extrabold my-6">
            Settings
          </div>
          <div class="flex flex-no-wrap items-center justify-between py-2">
            <div>
              <div class="text-gray-800">Disable Measurements</div>
              <div class="text-gray-600">Disable Height and Weight fields</div>
            </div>
            <div class="text-black">
              <VueToggles
                :width="50"
                checkedBg="#19ca91"
                v-if="settings"
                :value="settings.disableMeasurements"
                @click="updateSetting('disableMeasurements', !settings.disableMeasurements)"
                :sync="true"
              />
            </div>
          </div>
        </div>
      </modal>
    </template>
  </full-screen>
</template>
<script>
import APIProvider from "../api/registration";
import tour from "../api/tour";
import auth from "../auth/index";
import { router } from "../router";

import { getTourBrand } from "../helpers/brand";
import AxiosErrorHandler from "../helpers/error";
import Settings from "../helpers/settings";
const SettingsProvider = new Settings();

import { VueToggles } from "vue-toggles";
import FullScreen from "@/components/FullScreen.vue";
import LocaleChanger from "@/components/LocaleChanger.vue";
import LoginForm from "@/components/LoginForm.vue";
import Modal from "@/components/Modal.vue";
import QrCode from "@/components/QrCode.vue";
import SearchRegistration from "@/components/SearchRegistration.vue";
import Spinner from "@/components/Spinner.vue";

const VALID_STATUS = ["registered"];

export default {
  components: {
    FullScreen,
    LocaleChanger,
    LoginForm,
    Modal,
    QrCode,
    SearchRegistration,
    Spinner,
    VueToggles,
  },
  data() {
    return {
      registrations: [],
      tour: {
        name: "",
      },
      email: null,
      isLoading: true,
      disableSearch: false,
      searchHasBeenProcessed: false,
      showLoginModal: false,
      showSettingsModal: false,
      settings: null,
    };
  },
  methods: {
    getBrand() {
      if (this.tour.group) {
        return getTourBrand(this.tour.group.id);
      }
    },
    isValid(registration) {
      return VALID_STATUS.includes(registration.status);
    },
    checkUserAndRedirectToHome() {
      this.showLoginModal = true;
    },
    editSettings() {
      this.showSettingsModal = true;
    },
    checkLogin(credential) {
      auth.login(credential).then(() => {
        router.push({ name: "home" });
      });
    },
    searchRegistrations(email) {
      var vm = this;
      const api = new APIProvider(this.tour);

      vm.email = email;
      vm.disableSearch = true;
      vm.registrations = [];
      vm.searchHasBeenProcessed = false;

      api
        .searchByEmail(email)
        .then((response) => {
          vm.registrations = response.data.data;
          vm.searchHasBeenProcessed = true;
        })
        .catch((error) => {
          vm.searchHasBeenProcessed = false;
          AxiosErrorHandler(error);
        })
        .finally(() => {
          vm.disableSearch = false;
        });
    },
    resertSearch() {
      this.searchHasBeenProcessed = false;
      this.registrations = [];
    },
    updateSetting(props, value) {
      SettingsProvider.set(props, value);
      this.settings = SettingsProvider.all();
    },
  },
  mounted() {
    var vm = this;
    tour
      .get(this.$route.params.tour, this.$i18n.locale)
      .then((tour) => {
        this.tour = tour;
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
        AxiosErrorHandler(error);
      });
    this.settings = SettingsProvider.all();
  },
};
</script>
