<template>
    <div class="w-full m-auto flex min-h-screen align-center justify-center bg-gray-100">
        <login-form  class="bg-white shadow-md rounded px-8 pt-6 pb-8 m-4 self-center"
            @form:submit="login($event)" />
    </div>
</template>
<script>
import auth from '../auth/index'
import account from '../auth/account'
import {router} from '../router'
import LoginForm from "@/components/LoginForm.vue";


export default {
    components: {
        LoginForm,
    },  
    methods: {
        login(credentials) {
            auth.login(credentials)
            .then((key) => {
                account.get()
                .then(() => {
                    router.push({name: 'security-code'})
                });
            })
        }
    }

}
</script>