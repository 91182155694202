import NotifyConfig from '../conf/notify'
import router from '../router'
import { notify } from "@kyvg/vue3-notification";


const AxiosErrorHandler = function(error) {
    notify({ ...NotifyConfig, ...{
            title: 'Error',
            text: error.response.data && error.response.data.error ? 
                error.response.data.error.message : error.response.status
        }
    });

    if (error.response.status === 401) {
        router.push({ name: 'login' })
    }
}

export default AxiosErrorHandler;