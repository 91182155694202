<template>
  <full-screen>
    <template v-slot:header>
      <div class="flex justify-between m-3">
        <router-link
          :to="{ name: 'security-code' }"
          class="flex  items-center text-blue-500"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-4 mr-1 icon-blue-500"
          >
            <path
              class="primary"
              d="M4 4l8-2 8 2v9.06a8 8 0 0 1-4.42 7.15L12 22l-3.58-1.79A8 8 0 0 1 4 13.06V4z"
            />
            <path
              class="secondary"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          <span>Security code</span>
        </router-link>
        <button v-on:click="logout()" class="flex  items-center text-blue-500">
          <span>Logout</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-4 ml-1  icon-blue-500"
          >
            <path
              class="primary"
              d="M11 4h3a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V6h-2v12h2v-2a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1h-3v1a1 1 0 0 1-1.27.96l-6.98-2A1 1 0 0 1 2 19V5a1 1 0 0 1 .75-.97l6.98-2A1 1 0 0 1 11 3v1z"
            />
            <path
              class="secondary"
              d="M18.59 11l-1.3-1.3c-.94-.94.47-2.35 1.42-1.4l3 3a1 1 0 0 1 0 1.4l-3 3c-.95.95-2.36-.46-1.42-1.4l1.3-1.3H14a1 1 0 0 1 0-2h4.59z"
            />
          </svg>
        </button>
      </div>
    </template>
    <div class="text-center text-4xl font-extrabold mt-6">
      {{ $t("tours.title") }}
    </div>
    <div class="mx-auto max-w-3xl">
      <div class="my-5">
        <search-input @search:input="search = $event" :placeholder="$t('tours.search')" />
      </div>
      <div class="overflow-y-scroll" style="height: 400px">
        <div v-if="isLoading" class="text-center pt-24">
          <spinner
            class="w-16 h-16 text-gray-400 stroke-current mx-auto"
          ></spinner>
          <span class="text-gray-400 font-extrabold text-2xl">Loading....</span>
        </div>
        <div v-else>
          <router-link
            :to="{ name: 'tour', params: { tour: tour.slug } }"
            v-for="tour in results"
            v-bind:key="tour.id"
            class="border-b border-grey-light border-solid p-4 flex no-underline"
          >
            <div class="mr-4 self-center">
              <img
                v-if="tour.media.logo"
                :src="tour.media.logo"
                :alt="tour.name"
                class="rounded-full w-8"
              />
              <div v-else class="rounded-full w-8">
                <svg
                  style="enable-background:new 0 0 50 50;"
                  viewBox="0 0 50 50"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Layer_1">
                    <polygon
                      points="2.707,48.707 25,26.414 47.293,48.707 48.707,47.293 26.414,25 48.707,2.707 47.293,1.293 25,23.586 2.707,1.293    1.293,2.707 23.586,25 1.293,47.293  "
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div class="self-center">
              <div class="text-2xl">{{ tour.name }}</div>
              <div class="text-gray-400 block">
                <strong>{{ getBrandName(tour) }} </strong>
                <span v-if="tour.start_at || tour.end_at">
                  <span v-if="tour.start_at">{{
                    dayjs(tour.start_at).format(" lll")
                  }}</span>
                  ->
                  <span v-if="tour.end_at">{{
                      dayjs(tour.end_at).format("lll")
                  }}</span>
                </span>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="flex justify-items-center justify-center w-full mt-4">
        <button
          v-on:click="getTours()"
          class="border rounded-md p-1 m-1 border-gray-400 text-gray-400 text-sm flex items-center"
        >
          <svg
            class="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
          Refresh events
        </button>
      </div>
    </div>
  </full-screen>
</template>
<script>
import Fuse from "fuse.js";
import login from "../auth/index";
import tour from "../api/tour";
import { getTourBrand } from "../helpers/brand";

import AxiosErrorHandler from "../helpers/error";

import SearchInput from "@/components/SearchInput.vue";
import Spinner from "@/components/Spinner.vue";
import FullScreen from "@/components/FullScreen.vue";
import dayjs from "dayjs";

export default {
  components: {
    FullScreen,
    SearchInput,
    Spinner,
  },
  data() {
    return {
      fuse: null,
      search: "",
      isLoading: false,
      tours: [],
      fuseOptions: {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["name"],
      },
    };
  },
  computed: {
    dayjs() {
      return dayjs
    },
    results: function() {
      if (this.search.trim() === "") {
        return this.tours;
      } else {
        return this.fuse.search(this.search.trim());
      }
    },
  },
  methods: {
    logout() {
      login.logout();
    },
    getBrandName(tour) {
      let brand = getTourBrand(tour.group.id);
      return brand ? brand.name : "";
    },
    getTours: function() {
      this.isLoading = true;
      tour
        .getAll()
        .then((response) => {
          // const tours = JSON.JSON.stringify({ ...response.data.data });
          const tours = response.data.data.filter((tour) => {
            return getTourBrand(tour.group.id) !== undefined;
          });
          this.tours = tours;
          this.fuse = new Fuse(tours, this.fuseOptions);
          this.result = this.tours;
        })
        .catch((error) => {
          AxiosErrorHandler(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getTours();
  },
};
</script>
