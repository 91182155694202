const STORAGE_KEY = 'settings';

const PROPS = {
    disableMeasurements : false,
}

class Settings {

    set(props, value) {
        var settings = JSON.parse(localStorage.getItem(STORAGE_KEY))
        if(!settings) {
            settings = {...PROPS}
        }
        settings[props] = value;
        localStorage.setItem(STORAGE_KEY, JSON.stringify(settings))
    }

    get(props) {
        return {
            ...PROPS,
            ...JSON.parse(localStorage.getItem(STORAGE_KEY))
        }[props]
    }
    
    all() {
        return {
            ...PROPS,
            ...JSON.parse(localStorage.getItem(STORAGE_KEY))
        }
    }
}

export default Settings;