<template>
    <full-screen>
        <template v-slot:header>
            <button class="text-blue-500 mt-3 inline-block" 
                v-on:click="$emit('back')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 icon inline-block text-blue-500 fill-current">
                    <path d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"/>
                </svg>
                {{ $t("step-2.header.back") }}
            </button>
        </template>

        <div class="text-center">
            <div class="text-4xl font-extrabold mt-6">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-24  mb-4 mx-auto icon-blue-500">
                    <path class="primary" d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm13 3a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2h-2zm-2 4a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2h-4zm1 4a1 1 0 0 0 0 2h3a1 1 0 0 0 0-2h-3z"/>
                    <path class="secondary" d="M8 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm-2 2h4a2 2 0 0 1 2 2v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1c0-1.1.9-2 2-2z"/>
                </svg>
                {{ $t('step-2.title') }}
            </div>
            <div class="text-xl text-gray-600 mb-8">
                {{ $t('step-2.subtitle') }}
            </div>    
        </div>

        <div class=" flex flex-col justify-center align-middle text-center" style="height: 500px">
            <div class="overflow-x-scroll">
                <img v-if="hasImage" :src="image" class="mx-auto mb-3" width="400">
            </div>
            <image-uploader
                class=""
                :debug="1"
                :maxWidth="600"
                :quality="0.6"
                outputFormat="string"
                :scaleRatio="0.5"
                capture='camera'
                :preview=false
                :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
                v-on:input="setImage"
                >
                <label for="fileInput" slot="upload-label" class="text-center cursor-pointer">
                    <figure v-if="!hasImage">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  class="w-56 mx-auto icon">
                            <path class="primary" d="M6.59 6l2.7-2.7A1 1 0 0 1 10 3h4a1 1 0 0 1 .7.3L17.42 6H20a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h2.59zM19 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-7 8a5 5 0 1 0 0-10 5 5 0 0 0 0 10z"/>
                            <path class="secondary" d="M12 16a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        </svg>
                    </figure>
                    <span v-if="hasImage" class="border border-blue-500 text-blue-500 rounded p-3 mt-7 inline-block">{{ $t('step-2.update-picture') }}</span>
                    <span v-else class="border border-blue-500 text-blue-500 rounded p-3 inline-block">{{ $t('step-2.open-camera') }}</span>
                </label>
            </image-uploader>
        </div>
        <template v-slot:footer>
            <button type="submit" class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4  w-1/2"
                :class="{'opacity-50 cursor-not-allowed' : !hasImage}"
                :disabled="!hasImage"
                v-on:click="submitStep"
                >{{ $t("next-step") }}</button>
        </template>
    </full-screen>
</template>
<script>
import FullScreen from '@/components/FullScreen.vue'
import ImageUploader from "@/components/ImageUploader.vue";
export default {
    components: {
      ImageUploader,
        FullScreen,
    },
    props: {
        registration: {
            required: true,
        },
    },
    data() {
        return {
            hasImage : false,
            image: null,
        }
    },
    watch: {
        registration : function(r) {
            this.setImageFromRegistration()
        }
    },
    methods: {
        setImage: function (string) {
          if(typeof string === 'string') {
            this.hasImage = true
            this.image = string
          }
        },
        submitStep: function() {
          this.$emit('proofOfIdentityAdded', this.image);
        },
        setImageFromRegistration : function() {
            if(this.registration.proofOfIdentities && this.registration.proofOfIdentities.length > 0) {
                this.image = this.registration.proofOfIdentities[0].image
                this.hasImage = true
            }
        }
    },
    mounted() {
        this.setImageFromRegistration()
    },
    
}
</script>
<style>
.fileinput {
    display : none;
}
</style>