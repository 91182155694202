const brands = [
    {
        name : "Salomon",
        short : "sal",
        groupId : import.meta.env.VITE_APP_ID_GROUP_SAL,
    },
    {
        name : "Atomic",
        short : "ato",
        groupId : import.meta.env.VITE_APP_ID_GROUP_ATO,
    },
    {
        name : "Atomic & Salomon",
        short : "ato-sal",
        groupId : import.meta.env.VITE_APP_ID_GROUP_ATOSAL,
    }
]

export const ATO_GROUP_ID = import.meta.env.VITE_APP_ID_GROUP_ATO
export const SAL_GROUP_ID = import.meta.env.VITE_APP_ID_GROUP_SAL
export const ATOSAL_GROUP_ID = import.meta.env.VITE_APP_ID_GROUP_ATOSAL

export function getTourBrand(groupId) {
    return brands.find(brand => parseInt(brand.groupId) === parseInt(groupId))
}

export function getTourBrandsArray(groupId) {
    if (parseInt(groupId) === parseInt(ATOSAL_GROUP_ID)) {
        return [
            brands[1],
            brands[0],
        ]
    }
    return brands.filter(brand => parseInt(brand.groupId) === parseInt(groupId))
}

export function getRegistrationBrand(registration) {
    if(!registration.extrafield_brand) {
        return null;
    }
    return brands.find(brand => brand.short.toLowerCase() === registration.extrafield_brand.toLowerCase())
}

