<template>
  <Field
    :name="vid"
    :rules="rules"
    v-slot="{ field, errors }"
    :value="value"
    v-model="valueWatched"
  >
    <label
      class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
      for="grid-first-name"
    >
      {{ label }}
    </label>
    <input
      :placeholder="placeholder"
      v-bind:value="value"
      autocomplete="off"
      spellcheck="false"
      :type="type"
      :min="min"
      :max="max"
      v-bind="field"
      :maxlength="maxlength"
      v-on:input="$emit('input', $event.target.value)"
      class="transition focus:outline-0
            border border-transparent focus:outline-none focus:bg-white focus:border-gray-300 
            placeholder-gray-600 rounded bg-gray-200 py-2 px-4 block w-full appearance-none leading-normal text-lg"
    />
    <span class="text-red-500 text-xs">{{ errors[0] }}</span>
  </Field>
</template>

<script>
import { Field } from "vee-validate";

export default {
  inheritAttrs: false,
  components: {
    Field,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    value: [String, Number],
  },
  data() {
    return {
      valueWatched: this.value,
    };
  },
  watch: {
    value: function (val) {
      this.valueWatched = val;
    },
  }
};
</script>

<style></style>
