var getFieldOptions = function (tour, name) {
  let field = getField(tour, name)
  if (field === undefined) {
    return [];
  }
  return field.templateOptions.options.map((option) => {
    return {
      value: option.value,
      text: option.name,
      disabled: option.disabled,
    };
  });
}

var isFieldAvailable = function (tour, name) {
  return getField(tour, name) !== undefined
}

var getField = function (tour, name) {
  return tour.fields.find((field) => field.key === name);
}

export {
  getFieldOptions,
  isFieldAvailable
}

