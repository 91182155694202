import APIProvider from '../api/account';
import AxiosErrorHandler from '../helpers/error'

const STORAGE_KEY = 'user'


export default {

    STORAGE_KEY,
    
    get : function() {
        const api = new APIProvider();
        return new Promise((resolve, reject) => {
            if (!localStorage.getItem(STORAGE_KEY)) {
                api.get()
                .then((response) => {
                    var user = response.data;
                    localStorage.setItem(STORAGE_KEY, JSON.stringify(user))
                    resolve(user);
                })
                .catch((error) => {
                    reject()
                    AxiosErrorHandler(error);
                });
            } else {
                resolve(JSON.parse(localStorage.getItem(STORAGE_KEY)));
            }
        });
    },
    destroye : function() {
        localStorage.removeItem(STORAGE_KEY)
    },
}

