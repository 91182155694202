<template>
  <div class="flex space-x-4 my-4">
    <div v-for="(lang, i) in langs" :key="`Lang${i}`" class="border rounded-lg flex-1  text-center p-3" v-on:click.prevent="setLocale(lang.code)"
      :class="[ isCurrent(lang.code) ?  'border-blue-500 bg-blue-100' : '' ]">
      <span :class="[ isCurrent(lang.code) ?  'text-blue-500' : 'text-gray-500' ]" class="font-bold">{{ lang.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return {
      langs: [
        { code : 'en', name : 'English' },
        { code : 'de', name : 'Deutsch' },
        { code : 'fr', name : 'Français' },
        { code : 'it', name : 'Italiano' },
      ]
    }
  },
  methods : {
    isCurrent (code) {
      return this.$i18n.locale === code
    },
    setLocale (code) {
      this.$i18n.locale = code
    }
  }
}
</script>