<template>
    <canvas width="550" height="230"></canvas>
</template>
<script>

import SignaturePad from 'signature_pad';

export default {
    data: function () {
        return {
            signPad : null,
            signatureIsEmpty : true,
        }
    },
    methods: {
        resetSignature : function() {
            this.signPad.clear();
            this.signatureIsEmpty = true;
            this.$emit('clear');
        }
    },
    mounted() {
        let vm = this;
        let canvas = document.querySelector("canvas");
        this.signPad = new SignaturePad(canvas, {
            onEnd: function() {
                if(!this.isEmpty()) {
                    vm.signatureIsEmpty = false;
                    vm.$emit('sign', this.toDataURL())
                }
            }
        });
    },

}
</script>
<style lang="css" scoped>

</style>