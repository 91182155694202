<template>
  <full-screen>
    <template v-slot:header>
      <button
        v-if="!$route.params.registration"
        v-on:click="$emit('back')"
        class="text-blue-500 mt-3 inline-block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-8 icon inline-block text-blue-500 fill-current"
        >
          <path
            d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
          />
        </svg>
        {{ $t("step-1.header.back") }}
      </button>
      <router-link
        v-else
        :to="{ name: 'tour', params: { tour: tour.slug } }"
        class="text-blue-500 mt-3 inline-block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-8 icon inline-block text-blue-500 fill-current"
        >
          <path
            d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
          />
        </svg>
        {{ $t("step-1.header.cancel") }}
      </router-link>
    </template>

    <div class="text-center">
      <div class="text-4xl font-extrabold mt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-24 mb-4 mx-auto icon-blue-500"
        >
          <circle cx="12" cy="12" r="10" class="primary" />
          <path
            class="secondary"
            d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
          />
        </svg>
        {{ $t("step-1.title") }}
      </div>
      <div class="text-xl text-gray-600 mb-8">{{ $t("step-1.subtitle") }}</div>
    </div>

    <registration-field name="name" :value="registration.firstname + ' ' + registration.lastname"></registration-field>
    <registration-field name="email" :value="registration.email"></registration-field>

    <registration-field v-if="birthday" name="birthday" @registrationInput="birthday = $event" type="date" rules="required" :value="birthday" editable></registration-field>
    <registration-field
      v-if="isFieldAvailable(tour, 'extrafield_din_skier_weight')"
      name="weight"
      @registrationInput="r.extrafield_din_skier_weight = $event"
      type="select"
      rules="required"
      :value="r.extrafield_din_skier_weight"
      :options="getFieldOptions(tour, 'extrafield_din_skier_weight')"
      :editable="!disableMeasurements"
    ></registration-field>
    <registration-field
      v-if="isFieldAvailable(tour, 'extrafield_din_skier_height')"
      name="height"
      @registrationInput="r.extrafield_din_skier_height = $event"
      type="select"
      :value="r.extrafield_din_skier_height"
      :options="getFieldOptions(tour, 'extrafield_din_skier_height')"
      rules="required"
      :editable="!disableMeasurements"
    ></registration-field>
    <registration-field
      v-if="isFieldAvailable(tour, 'extrafield_din_skier_sole_length')"
      name="sole-length"
      :value="r.extrafield_din_skier_sole_length"
      @registrationInput="r.extrafield_din_skier_sole_length = $event"
      rules="required|between:100,400"
      editable
    ></registration-field>
    <registration-field
      v-if="isFieldAvailable(tour, 'extrafield_din_skier_type')"
      name="ski-level"
      @registrationInput="r.extrafield_din_skier_type = $event"
      :value="r.extrafield_din_skier_type"
      type="select"
      :options="getFieldOptions(tour, 'extrafield_din_skier_type')"
      :help="$t('registration.fields.ski-level-help')"
      rules="required"
      editable
    ></registration-field>

    <registration-field
      v-if="isFieldAvailable(tour, 'extrafield_din_recommended')"
      name="recommended-din"
      :value="recommendedDIN"
    ></registration-field>

    <div class="border-b border-gray-300" v-if="isFieldAvailable(tour, 'extrafield_din_selected')">
      <div class="flex flex-no-wrap items-center justify-between py-2">
        <div class="text-gray-800">{{ $t("step-1.override-din-value") }}</div>
        <div class="text-black">
          <VueToggles v-model="overrideRecommendedDin" :width="50" checkedBg="#19ca91" />
        </div>
      </div>
      <transition name="fade">
        <div v-if="overrideRecommendedDin" class="bg-gray-100 px-4 py-4">
          <p class="text-center text-gray-800 font-bold">{{ $t("step-1.din-selected") }}</p>
          <div class="pt-2">
            <din-setting v-if="dinWanted" :value="dinWanted" @input="dinWanted = $event"></din-setting>
          </div>
        </div>
      </transition>
    </div>

    <div class="border-b border-gray-300" v-if="displayOtherBrandAsWell">
      <div class="flex flex-no-wrap items-center justify-between py-2">
        <div class="text-gray-800">{{ $t("step-1.brand-as-well." + brandAsWellTranslatation) }}</div>
        <div class="text-black">
          <VueToggles
            :width="50"
            checkedBg="#19ca91"
            :value="brandAsWellPropsValue() == 'yes'"
            @click="updateBrandAsWell(brandAsWellProps, brandAsWellPropsValue() !== 'yes')"
          />
        </div>
      </div>
    </div>

    <div v-for="brand in brandsLikeToTest" :key="brand.short" class="border-b border-gray-300">
      <div class="flex flex-no-wrap items-center justify-between py-2">
        <div class="text-gray-800">{{ $t("step-1.brand-like-to-test." + brand.short) }}</div>
        <div class="text-black">
          <VueToggles
            :width="50"
            checkedBg="#19ca91"
            :value="
              brandAsWellPropsValue(
                'extrafield_want_to_test_' + brand.name.toLowerCase()
              ) == 'yes'
            "
            @click="
              updateBrandAsWell(
                ['extrafield_want_to_test_' + brand.name.toLowerCase()],
                brandAsWellPropsValue(
                'extrafield_want_to_test_' + brand.name.toLowerCase()
              ) !== 'yes'
              )
            "
          />
        </div>
      </div>
    </div>

    <template v-slot:footer>
      <button
        type="submit"
        :disabled="disableSubmit"
        :class="{ 'opacity-50 cursor-not-allowed': disableSubmit }"
        class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4 w-1/2"
        v-on:click="submitStep"
      >{{ $t("next-step") }}</button>
    </template>
  </full-screen>
</template>
<script>


import DinSetting from "@/components/DinSetting.vue";
import FullScreen from "@/components/FullScreen.vue";
import RegistrationField from "@/components/RegistrationField.vue";
import { VueToggles } from "vue-toggles";
import {
  ATOSAL_GROUP_ID,
  getTourBrand,
  getTourBrandsArray,
  getRegistrationBrand,
} from "../helpers/brand";
import { calcZValue, soleLengthToRange } from '@nineteen-2/dtc-din'
import Settings from "../helpers/settings";
import { getFieldOptions, isFieldAvailable } from "../helpers/fields";
import dayjs from "dayjs";

const SettingsProvider = new Settings();

export default {
  components: {
    DinSetting,
    FullScreen,
    RegistrationField,
    VueToggles,
  },
  props: {
    tour: {
      type: Object,
    },
    registration: {
      type: Object,
    },
  },
  data() {
    return {
      dinWanted: null,
      overrideRecommendedDin: false,
      birthday: undefined,
      r: {
        extrafield_din_skier_weight: undefined,
        extrafield_din_skier_height: undefined,
        extrafield_din_skier_sole_length: undefined,
        extrafield_din_skier_type: undefined,
      },
      extrafield_want_to_test_salomon: null,
      extrafield_want_to_test_atomic: null,
      disableMeasurements: false,
      getFieldOptions,
      isFieldAvailable,
      disableSubmit: false,
    };
  },
  computed: {
    recommendedDIN: function () {
      return this.isFieldAvailable(this.tour, 'extrafield_din_recommended') ?
        calcZValue(
          this.r.extrafield_din_skier_weight,
          this.r.extrafield_din_skier_height,
          this.r.extrafield_din_skier_type,
          soleLengthToRange(this.r.extrafield_din_skier_sole_length),
          dayjs().diff(this.birthday, "years", false)
        )
        : null;
    },
    displayOtherBrandAsWell: function () {
      if (!this.$route.params.registration) {
        return false; // in case of registration creation , it's not "AsWell"
      }
      if (getTourBrand(this.tour.group.id)) {
        return (
          parseInt(getTourBrand(this.tour.group.id).groupId) !==
          parseInt(ATOSAL_GROUP_ID)
        ) && getRegistrationBrand(this.registration);
      }
      return false;
    },
    brandsLikeToTest: function () {
      if (
        !this.$route.params.registration &&
        parseInt(this.tour.group.id) === parseInt(ATOSAL_GROUP_ID)
      ) {
        return getTourBrandsArray(this.tour.group.id); // only return something if new registration on ATO+SAL event
      }
      return [];
    },
    brandAsWellTranslatation: function () {
      if (!getRegistrationBrand(this.registration)) {
        console.log('no brand found');
        return null;
      }
      if (getRegistrationBrand(this.registration).short === "ato") {
        return "sal";
      }
      return "ato";
    },
    brandAsWellProps: function () {
      if (!getRegistrationBrand(this.registration)) {
        return null;
      }
      if (getRegistrationBrand(this.registration).short === "ato") {
        return "extrafield_want_to_test_salomon";
      }
      return "extrafield_want_to_test_atomic";
    },
  },
  watch: {
    overrideRecommendedDin: function (isCheck) {
      if ((isCheck && !this.dinWanted) || !isCheck) {
        this.dinWanted = this.registration.extrafield_din_recommended ? parseFloat(this.registration.extrafield_din_recommended) : parseFloat(this.recommendedDIN);
      }
    },
    registration: function (registration) {
      var dinWanted = registration.extrafield_din_recommended;
      if (
        registration.extrafield_din_recommended !=
        registration.extrafield_din_selected
      ) {
        dinWanted = registration.extrafield_din_selected;
        this.overrideRecommendedDin = true;
      }
      this.dinWanted = parseFloat(dinWanted);
    },
    dinWanted: function (val) {
      if (!val) {
        this.dinWanted =
          parseFloat(this.registration.extrafield_din_recommended) ||
          parseFloat(this.registration.extrafield_din_selected) ||
          6; // default to 6
      }
    },
  },
  methods: {
    updateBrandAsWell: function (props, value) {
      this[props] = value ? "yes" : "no";
    },

    brandAsWellPropsValue: function (props = undefined) {
      if (props) {
        return this[props];
      }
      return this[this.brandAsWellProps];
    },

    submitStep: function () {
      var din = this.recommendedDIN;
      if (this.overrideRecommendedDin &&  this.isFieldAvailable(this.tour, 'extrafield_din_selected')) {
        din = this.dinWanted;
      }
      this.$emit("Step1Validated", {
        ...this.r,
        ...{
          birthday: this.birthday,
          extrafield_din_recommended: this.recommendedDIN,
          extrafield_din_selected: din,
          extrafield_want_to_test_atomic: this.extrafield_want_to_test_atomic,
          extrafield_want_to_test_salomon: this.extrafield_want_to_test_salomon,
        },
      });
    },
  },
  mounted() {
    const testAto = getTourBrandsArray(this.tour.group.id).find(
      (brand) => brand.short == "ato"
    )
      ? "yes"
      : null;
    const testSal = getTourBrandsArray(this.tour.group.id).find(
      (brand) => brand.short == "sal"
    )
      ? "yes"
      : null;
    this.extrafield_want_to_test_atomic =
      this.registration.extrafield_want_to_test_atomic || testAto;
    this.extrafield_want_to_test_salomon =
      this.registration.extrafield_want_to_test_salomon || testSal;

    this.birthday = this.registration.birthday
      ? dayjs(this.registration.birthday).format("YYYY-MM-DD")
      : null;

    this.r = {
      extrafield_din_skier_height: this.registration
        .extrafield_din_skier_height,
      extrafield_din_skier_sole_length: this.registration
        .extrafield_din_skier_sole_length,
      extrafield_din_skier_type: this.registration.extrafield_din_skier_type,
      extrafield_din_skier_weight: this.registration
        .extrafield_din_skier_weight,
    };

    if (
      false
      // !this.registration.extrafield_din_recommended
      // || this.registration.extrafield_din_recommended != this.registration.extrafield_din_selected
    ) {
      this.overrideRecommendedDin = true;
    }
    if (this.registration.extrafield_din_selected) {
      this.dinWanted = parseFloat(this.registration.extrafield_din_selected);
      this.overrideRecommendedDin = true;
    }

    this.disableMeasurements = SettingsProvider.get("disableMeasurements");
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
