<template>
  <div
      v-if="offlineReady || needRefresh"
      class="fixed right-0 bottom-0 m-4 p-3 border border-gray-400 border-solid rounded shadow bg-white z-10"
      role="alert"
  >
    <div class="mb-3">
      <span v-if="offlineReady">
        App ready to work offline
      </span>
      <span v-else>
        New content available, click on reload button to update.
      </span>
    </div>
    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" v-if="needRefresh" @click="updateServiceWorker()">
      Reload
    </button>
    <button class="border border-blue-500 text-blue-500 font-bold py-2 px-4 rounded" @click="close">
      Close
    </button>
  </div>
</template>
<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW()

const close = async () => {
  offlineReady.value = false
  needRefresh.value = false
}
</script>