
import auth from '../auth'
import axios from "axios";

class APIProvider {
    get() {
        return axios.get(import.meta.env.VITE_APP_API_ENDPOINT+'/api/account', {
            headers: auth.getAuthHeader()
        });
    }
}

export default APIProvider;