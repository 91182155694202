<template>
  <full-screen>
    <template v-slot:header>
      <button
        class="text-blue-500 mt-3 inline-block"
        v-on:click="$emit('back')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-8 icon inline-block text-blue-500 fill-current"
        >
          <path
            d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
          />
        </svg>
        {{ $t("step-3.header.back") }}
      </button>
    </template>

    <div class="text-center">
      <div class="text-4xl font-extrabold mt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-24  mb-4 mx-auto icon-blue-500"
        >
          <path
            class="primary"
            d="M4 3h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm16 12V7a2 2 0 0 1-2-2H6a2 2 0 0 1-2 2v8a2 2 0 0 1 2 2h12c0-1.1.9-2 2-2zM8 7h8a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2z"
          />
          <path
            class="secondary"
            d="M11.65 18.23a4 4 0 1 1 4.7 0l2.5 3.44-2.23-.18-1.48 1.68-.59-4.2a4.04 4.04 0 0 1-1.1 0l-.6 4.2-1.47-1.68-2.23.18 2.5-3.44zM14 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
          />
        </svg>
        {{ $t("step-3.title") }}
      </div>
      <div class="text-xl text-gray-600 mb-8">
        {{ $t("step-3.subtitle") }}
      </div>
    </div>

    <div class="border-gray-400">
      <div class="bg-white mb-4">
        <registration-field
          name="name"
          :value="registration.firstname + ' ' + registration.lastname"
        ></registration-field>
        <registration-field 
          v-if="isFieldAvailable(tour, 'extrafield_din_skier_sole_length')"
          name="sole-length"
          :value="registration.extrafield_din_skier_sole_length"
        ></registration-field>
        <registration-field
          v-if="isFieldAvailable(tour, 'extrafield_din_recommended')"
          name="recommended-din"
          :value="registration.extrafield_din_recommended"
        ></registration-field>
        <registration-field
          v-if="isFieldAvailable(tour, 'extrafield_din_selected')"
          name="selected-din"
          :value="registration.extrafield_din_selected"
        ></registration-field>
        <div
          class=" text-sm text-red-500 text-justify"
          v-if="
            (isFieldAvailable(tour, 'extrafield_din_recommended')  && isFieldAvailable(tour, 'extrafield_din_selected') ) && 
            (registration.extrafield_din_recommended !=
              registration.extrafield_din_selected)
          "
        >
          {{ $t("step-3.din-overide-warning") }}
        </div>
      </div>
    </div>

    <div class="flex justify-center flex-col">
      <div v-if="signature">
        <div class="bg-gray-100 border border-gray-500 my-1">
          <img :src="signature" alt="" />
        </div>
      </div>
      <div v-else class="bg-gray-100 border border-gray-500 my-1">
        <signature-pad
          ref="signature"
          v-on:sign="setSignature($event)"
        ></signature-pad>
      </div>
      <div v-if="signature || signatureRaw" class="text-right">
        <button
          href
          class="text-red-500 underline"
          v-on:click.prevent="resetSignature()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="w-8 icon inline-block fill-current"
          >
            <path
              fill-rule="evenodd"
              d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
            />
          </svg>
          {{ $t("step-3.signature.reset") }}
        </button>
      </div>
    </div>
    <template v-slot:footer>
      <div class=" max-w-xl mx-auto  mb-3 text-gray-500 text-xs text-justify">
        <span v-if="registrationAgeIsLessThen18">
          {{ $t("step-3.disclaimer.16-18") }}
        </span>
        <span v-else>
          {{ $t("step-3.disclaimer.more-than-18") }}
        </span>
        -
        <a
          href
          v-on:click.prevent="showModalSkiTestTerms = true"
          class="text-blue-500"
        >
          {{ $t("step-3.ski-tests-terms.title") }}</a
        >
        -
        <a
          href
          v-on:click.prevent="showModalPrivacyPolicy = true"
          class="text-blue-500"
        >
          {{ $t("step-3.privacy-policy.title") }}</a
        >
      </div>
      <button
        type="submit"
        class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4  w-1/2"
        :class="{
          'opacity-50 cursor-not-allowed': !signatureRaw && !signature,
        }"
        :disabled="!signatureRaw && !signature"
        v-on:click="submitStep"
      >
        {{ $t("next-step") }}
      </button>
    </template>
    <template v-slot:extra-footer>
      <modal
        :show="showModalSkiTestTerms"
        v-on:close="showModalSkiTestTerms = false"
      >
        <div class="text-center text-4xl font-extrabold mt-4 mb-3">
          {{ $t("step-3.ski-tests-terms.title") }}
        </div>
        <div
          class="w-3/4 self-center  overflow-y-scroll px-2 text-gray-600 mb-10"
          v-html="$t('ski-tests-terms')"
        ></div>
      </modal>
      <modal
        :show="showModalPrivacyPolicy"
        v-on:close="showModalPrivacyPolicy = false"
      >
        <div class="text-center text-4xl font-extrabold mt-4 mb-3">
          {{ $t("step-3.privacy-policy.title") }}
        </div>
        <div
          class="w-3/4 self-center  overflow-y-scroll px-2 text-gray-600 mb-10"
          v-html="$t('primacy-policy')"
        ></div>
      </modal>
    </template>
  </full-screen>
</template>
<script>
import FullScreen from "@/components/FullScreen.vue";
import Modal from "@/components/Modal.vue";
import RegistrationField from "@/components/RegistrationField.vue";
import SignaturePad from "@/components/SignaturePad.vue";
import { isFieldAvailable } from "../helpers/fields";


export default {
  components: {
    FullScreen,
    Modal,
    RegistrationField,
    SignaturePad,
  },
  props: {
    registration: {
      required: true,
    },
    tour: {
      required: true,
    },
  },
  computed: {
    registrationAgeIsLessThen18() {
      let birthday = new Date(this.registration.birthday);
      return (
        new Number(
          (new Date().getTime() - birthday.getTime()) / 31536000000
        ).toFixed(0) < 18
      );
    },
  },
  data() {
    return {
      signature: null,
      signatureRaw: null,
      showModalSkiTestTerms: false,
      showModalPrivacyPolicy: false,
      isFieldAvailable: isFieldAvailable,
    };
  },
  methods: {
    setSignature: function(e) {
      this.signatureRaw = e;
    },
    resetSignature: function() {
      this.signature = null;
      this.signatureRaw = null;

      if (this.$refs.signature) {
        this.$refs.signature.resetSignature();
      }
    },
    submitStep: function() {
      this.$emit(
        "signatureAdded",
        this.signatureRaw ? this.signatureRaw : this.signature
      );
    },
  },
  mounted() {
    this.signature = this.registration.signature;
  },
};
</script>
