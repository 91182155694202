<template>
    <div class="flex flex-col min-h-screen">
        <header class="mb-4">
            <slot name="header"></slot>
        </header>
        <main class="flex-grow">
            <div class=" max-w-xl mx-auto">
                <slot></slot>
            </div>
        </main>
        <footer class="content-end pt-2 pb-5 text-center">
            <slot name="footer"></slot>
        </footer>
        <slot name="extra-footer"></slot>
    </div>
</template>
<script>
export default {
    name: 'FullScreen',
}
</script>