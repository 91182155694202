const STORAGE_KEY = 'security_code';

export default {
    STORAGE_KEY,

    save(value) {
        localStorage.setItem(STORAGE_KEY, value)
    },
    get() {
        return localStorage.getItem(STORAGE_KEY)
    },
    isValid(code) {
        return code === this.get()
    },
    destroye(){
        localStorage.removeItem(STORAGE_KEY)
    },
}