<template>
  <full-screen>
    <template v-slot:header>
      <router-link
        :to="{ name: 'tour', params: { tour: tour.slug } }"
        class="text-blue-500 mt-3 inline-block"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-8 icon inline-block text-blue-500 fill-current"
        >
          <path
            d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
          />
        </svg>
        {{ $t("step-0.header.back") }}
      </router-link>
    </template>
    <div class="text-center">
      <div class="text-4xl font-extrabold mt-6 mb-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-24 mb-4 mx-auto icon-blue-500"
        >
          <circle cx="12" cy="12" r="10" class="primary" />
          <path
            class="secondary"
            d="M3.66 17.52A5 5 0 0 1 8 15h8a5 5 0 0 1 4.34 2.52 10 10 0 0 1-16.68 0zM12 13a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"
          />
        </svg>
        {{ $t("step-0.title") }}
      </div>
    </div>
    <Form v-slot="{ handleSubmit }">
      <form
        class="w-full"
        id="createRegistrationForm"
        v-on:submit.prevent="handleSubmit(submitForm)"
      >
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.firstname')"
              @input="consumer.firstname = $event"
              rules="required"
              vid="firstname"
              :value="consumer.firstname"
            ></text-input>
          </div>
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.lastname')"
              @input="consumer.lastname = $event"
              rules="required"
              vid="lastname"
              :value="consumer.lastname"
            ></text-input>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.birthday')"
              type="date"
              @input="birthday = $event"
              rules="required"
              vid="birthday"
              :value="birthday"
            ></text-input>
          </div>
          <div
            v-if="isFieldAvailable(tour, 'country')"
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <select-input
              :label="$t('registration.fields.country')"
              vid="country"
              rules="required"
              @input="consumer.country = $event"
              :options="getFieldOptions(tour, 'country')"
              v-on:input="setCountryCode($event)"
              v-model="consumer.country"
            ></select-input>
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full px-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.email')"
              rules="required|email"
              type="email"
              inputmode="email"
              @input="consumer.email = $event"
              :value="consumer.email"
              vid="email"
            ></text-input>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-3">
          <div class="w-full md:w-1/3 pl-3 pr-1 mb-6 md:mb-0">
            <select-input
              :label="$t('registration.fields.phone.country-code')"
              vid="phone_country"
              rules="required"
              @input="consumer.phone_country = $event"
              :options="getFieldOptions(tour, 'phone_country')"
              v-model="consumer.phone_country"
            ></select-input>
          </div>
          <div class="w-full md:w-2/3 pl-1 pr-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.phone.number')"
              type="phone"
              rules="required|phone:@phone_country"
              name="phone Number"
              inputmode="tel"
              @input="consumer.phone = $event"
              vid="phone"
              :value="consumer.phone"
            ></text-input>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2" v-if="!disableMeasurements">
          <div
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            v-if="isFieldAvailable(tour, 'extrafield_din_skier_weight')"
          >
            <select-input
              :label="$t('registration.fields.weight')"
              @input="consumer.extrafield_din_skier_weight = $event"
              rules="required"
              :options="getFieldOptions(tour, 'extrafield_din_skier_weight')"
              vid="weight"
              v-model="consumer.extrafield_din_skier_weight"
            ></select-input>
          </div>
          <div
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            v-if="isFieldAvailable(tour, 'extrafield_din_skier_height')"
          >
            <select-input
              :label="$t('registration.fields.height')"
              @input="consumer.extrafield_din_skier_height = $event"
              rules="required"
              :options="getFieldOptions(tour, 'extrafield_din_skier_height')"
              vid="height"
              v-model="consumer.extrafield_din_skier_height"
            ></select-input>
          </div>
        </div>
        <div
          class="flex flex-wrap -mx-3 mb-2"
          v-if="isFieldAvailable(tour, 'extrafield_din_skier_sole_length')"
        >
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <text-input
              :label="$t('registration.fields.sole-length')"
              inputmode="decimal"
              type="number"
              rules="required|between:100,400"
              @input="consumer.extrafield_din_skier_sole_length = $event"
              vid="sole_length"
              :value="consumer.extrafield_din_skier_sole_length"
            ></text-input>
          </div>
          <div
            class="w-full md:w-1/2 px-3 mb-6 md:mb-0"
            v-if="isFieldAvailable(tour, 'extrafield_din_skier_type')"
          >
            <select-input
              :label="$t('registration.fields.ski-level')"
              :help="$t('registration.fields.ski-level-help')"
              @input="consumer.extrafield_din_skier_type = $event"
              rules="required"
              :options="getFieldOptions(tour, 'extrafield_din_skier_type')"
              vid="ski_level"
              v-model="consumer.extrafield_din_skier_type"
            ></select-input>
          </div>
        </div>

        <div v-for="field in tour.fields">
          <template v-if="field.key === 'extrafield_newsletter'">
            <label
                v-for="brand in brands"
                :key="brand.short"
                :for="'nl_' + brand.short"
                class="block text-gray-500 leading-none mt-2"
            >
              <input
                  type="checkbox"
                  :id="'nl_' + brand.short"
                  class="mr-2 leading-tight"
                  v-model="consumer.extrafield_newsletter"
                  :disabled="disableNewsletter"
                  :value="brand.name.toLowerCase()"
              />
              <span class="text-xs" v-html="$t('registration.fields.newsletter.' + brand.short)"></span>
            </label>
          </template>
          <template v-if="field.key.startsWith('extrafield_') && field.type === 'checkbox'">
            <label
                :for="'checkbox_' + field.key"
                class="block text-gray-500 leading-none mt-2"
            >
              <input
                  type="checkbox"
                  :id="'checkbox_' + field.key"
                  class="mr-2 leading-tight"
                  v-model="consumer[field.key]"
                  :disabled="disableNewsletter && field.key.startsWith('extrafield_newsletter')"
              />
              <strong class="text-xs">{{ field.templateOptions?.label }}</strong>
            </label>
            <span v-if="field.templateOptions?.text" class="block text-xs text-gray-500 leading-none">{{ field.templateOptions.text }}</span>
          </template>
        </div>
      </form>
    </Form>
    <template v-slot:footer>
      <button
        type="submit"
        form="createRegistrationForm"
        class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4 w-1/2"
      >{{ $t("next-step") }}</button>
    </template>
  </full-screen>
</template>

<script>
import {  Form, defineRule } from "vee-validate";
import { required, email, between } from '@vee-validate/rules';
import { parsePhoneNumberFromString } from "libphonenumber-js/min";

import { getTourBrandsArray } from "../helpers/brand"
import { getFieldOptions, isFieldAvailable } from "../helpers/fields"
import { calcZValue, soleLengthToRange } from '@nineteen-2/dtc-din'
import Settings from "../helpers/settings"
const SettingsProvider = new Settings();

import FullScreen from "@/components/FullScreen.vue";
import SelectInput from "@/components/SelectInput.vue";
import TextInput from "@/components/TextInput.vue";
import dayjs  from "dayjs";

defineRule("email", email);
defineRule("required", required);
defineRule("between", between);

defineRule("phone", (value, [ phone_country ]) =>{
  if (parsePhoneNumberFromString(value, phone_country)) {
      return parsePhoneNumberFromString(value, phone_country).isValid();
    }
    return "Phone Number is Not Valid";
});

export default {
  components: {
    FullScreen,
    SelectInput,
    TextInput,
    Form,
  },
  props: {
    tour: {
      type: Object,
    },
    registration: {},
  },
  data() {
    return {
      birthday: null,
      disableNewsletter: true,
      consumer: {
        firstname: "",
        lastname: "",
        email: "",
        phone_country: null,
        phone: null,
        address: "",
        zipcode: "",
        city: "",
        country: null,
        extrafield_din_skier_weight: null,
        extrafield_din_skier_height: null,
        extrafield_din_skier_sole_length: null,
        extrafield_din_skier_type: null,
        extrafield_din_recommended: null,
        extrafield_newsletter: [],
        extrafield_newsletter_salomon: false,
        extrafield_newsletter_atomic: false,
        extrafield_want_to_test_atomic: false,
        extrafield_want_to_test_salomon: false,
      },
      disableMeasurements: false,
      getFieldOptions,
      isFieldAvailable,
    };
  },
  computed: {
    brands: function () {
      return getTourBrandsArray(this.tour.group.id);
    },
  },
  watch: {
    birthday(val) {
      if (dayjs().diff(dayjs(this.birthday), 'years') < 18) {
        this.disableNewsletter = true;
        this.consumer.extrafield_newsletter = [];
      } else {
        this.disableNewsletter = false;
      }
    },
  },
  methods: {
    setCountryCode: function (code) {
      if (!this.consumer.phone) {
        this.consumer.phone_country = code;
      }
    },
    submitForm: function () {
      this.$emit("newRegistration", {
        ...this.consumer,
        ...{
          birthday: dayjs(this.birthday).format('YYYY-MM-DD'),
          extrafield_din_recommended: this.isFieldAvailable(this.tour, 'extrafield_din_recommended') ?
            calcZValue(
              this.consumer.extrafield_din_skier_weight,
              this.consumer.extrafield_din_skier_height,
              this.consumer.extrafield_din_skier_type,
              soleLengthToRange(this.consumer.extrafield_din_skier_sole_length),
              dayjs().diff(dayjs(this.birthday), "years")
            )
            : null,
        },
      });
    },
  },
  mounted() {

    this.consumer = {
      ...this.consumer,
      ...{
        extrafield_want_to_test_atomic: getTourBrandsArray(
          this.tour.group.id
        ).find((brand) => brand.short == "ato")
          ? "yes"
          : null,
        extrafield_want_to_test_salomon: getTourBrandsArray(
          this.tour.group.id
        ).find((brand) => brand.short == "sal")
          ? "yes"
          : null,
      },
    };
    if (this.registration) {
      this.consumer = {
        ...this.consumer,
        ...this.registration,
      };
      this.birthday = this.registration.birthday
        ? dayjs(this.registration.birthday).format("YYYY-MM-DD")
        : null;
    }
    if (this.$route.query.email) {
      this.consumer.email = this.$route.query.email;
    }
    this.disableMeasurements = SettingsProvider.get("disableMeasurements");
  },
};
</script>

<style></style>
