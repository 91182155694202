<template>
    <div class="fixed w-full h-full top-0 left-0 bg-white flex align-center justify-center">
        <div class="self-center text-center">
            <div class="text-4xl font-extrabold mt-6">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-24  mb-4 mx-auto icon-blue-500">
                    <circle cx="12" cy="12" r="10" class="primary"/>
                    <path class="secondary" d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"/>
                </svg>
                {{ $t('confirmation.title') }}
            </div>
            <div class="text-xl text-gray-600 mb-8">
                {{ $t('confirmation.subtitle') }}
            </div>
            <div class="text-center mt-12">
                <router-link :to="{ name: 'tour', params: { tour: tour.slug }}" class="text-blue-500 border rounded-lg border-blue-500 text-2xl  py-2 px-4">
                    {{ $t('confirmation.back') }}
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props : {
        tour : {
            required: true,
        },
        registration : {
            required: true,
        },
    }
}
</script>