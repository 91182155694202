<template>
  <div class="h-screen flex align-center justify-center">
    <div class=" self-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="w-24 mx-auto icon-blue-500"
      >
        <path
          class="primary"
          d="M4 4l8-2 8 2v9.06a8 8 0 0 1-4.42 7.15L12 22l-3.58-1.79A8 8 0 0 1 4 13.06V4z"
        />
        <path
          class="secondary"
          d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
        />
      </svg>
      <div>
        <div class="text-4xl text-center font-extrabold mt-6">
          Security setting
        </div>
        <div class="text-xl text-center text-gray-600 mb-8">
          Please set the session security code
        </div>

        <form v-on:submit.prevent="saveSecuryCode()">
          <div v-if="displayLoginForm">
            <label class="uppercase text-gray-400 text-left">Your Email</label>
            <input
              type="text"
              v-model="email"
              class="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 px-4 block w-full appearance-none leading-normal ds-input text-xl"
            />

            <label class="uppercase text-gray-400 text-left"
              >Your Password</label
            >
            <input
              type="password"
              v-model="password"
              class="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 px-4 block w-full appearance-none leading-normal ds-input text-xl"
            />
          </div>
          <label class="uppercase text-gray-400 text-left"
            >Chose a session security code (4 digits)</label
          >
          <cleave
            v-model="securityCode"
            :options="{ blocks: [4], numericOnly: true }"
            inputmode="decimal"
            class="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 px-4 block w-full appearance-none leading-normal ds-input text-xl"
          />

          <button
            type="submit"
            class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4 w-full mt-6"
            :class="{ 'opacity-50 cursor-not-allowed': !canSave }"
          >
            Set security code
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "../auth/index";
import account from "../auth/account";
import security from "../auth/security-code";
import { router } from "../router";
import isValidEmail from "../helpers/email";
import Cleave from "vue-cleave-component";

export default {
  components: {
    Cleave,
  },
  data() {
    return {
      displayLoginForm: true,
      securityCode: "",
      email: "",
      password: "",
    };
  },
  computed: {
    canSave: function() {
      return (
        this.securityCode.length > 3 &&
        (!this.displayLoginForm ||
          (isValidEmail(this.email) && this.password.length > 3))
      );
    },
  },
  methods: {
    saveSecuryCode: function() {
      if (!this.displayLoginForm) {
        security.save(this.securityCode);
        router.push({ name: "home" });
      } else {
        auth
          .login({ email: this.email, password: this.password })
          .then((key) => {
            security.save(this.securityCode);
            router.push({ name: "home" });
          });
      }
    },
  },
  mounted() {
    if (auth.haveRecentlyIdentified()) {
      this.displayLoginForm = false;
    }
    let vm = this;
    account
      .get()
      .then((user) => {
        vm.email = user.email;
      })
      .catch(() => {
        router.push({ name: "login" });
      });
  },
};
</script>
