import Notifications from '@kyvg/vue3-notification'
import { createI18n } from 'vue-i18n'
import { createApp } from 'vue';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import App from "./App.vue";
import {router} from "./router";
import './assets/tailwind.css'

import dayjs from "dayjs";
import fr from "dayjs/locale/fr"
import de from "dayjs/locale/de"
import it from "dayjs/locale/it"
import localizedFormat from 'dayjs/plugin/localizedFormat'
/**
 * froala.com/online-html-editor
 * https://fr.functions-online.com/json_encode.html
 */
import i18n_de from "./i18n/de.json";
import i18n_en from "./i18n/en.json";
import i18n_fr from "./i18n/fr.json";
import i18n_it from "./i18n/it.json";
import './registerServiceWorker'
dayjs.extend(localizedFormat);
const i18n = createI18n(
    {
      locale: "en", // set locale
      fallbackLocale: "en",
      messages: {
        de: i18n_de,
        en: i18n_en,
        fr: i18n_fr,
        it: i18n_it,
      },
        specialCharacters: {
            linked: '~@',
        }
    }
)

const vueApp = createApp(App)
    .use(Notifications)
    .use(i18n)
    .use(router);



if (import.meta.env.NODE_ENV === 'production' || import.meta.env.NODE_ENV === 'staging') {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_APP_BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
 vueApp.use(bugsnagVue)
}
vueApp .mount('#app')