<template>
  <full-screen>
    <template v-slot:header>
      <button
        class="text-blue-500 mt-3 inline-block"
        v-on:click="$emit('back')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-8 icon inline-block text-blue-500 fill-current"
        >
          <path
            d="M13.7 15.3a1 1 0 0 1-1.4 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.4 1.4L10.42 12l3.3 3.3z"
          />
        </svg>
        {{ $t("step-4.header.back") }}
      </button>
    </template>

    <div class="text-center">
      <div class="text-4xl font-extrabold mt-6">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="w-24  mb-4 mx-auto icon-blue-500"
        >
          <path
            class="primary"
            d="M4 4l8-2 8 2v9.06a8 8 0 0 1-4.42 7.15L12 22l-3.58-1.79A8 8 0 0 1 4 13.06V4z"
          />
          <path
            class="secondary"
            d="M10.5 12.59l4.3-4.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-2-2a1 1 0 0 1 1.4-1.42l1.3 1.3z"
          />
        </svg>
        {{ $t("step-4.title") }}
      </div>
      <div class="text-xl text-gray-600 mb-8">
        {{ $t("step-4.subtitle") }}
      </div>
    </div>

    <div
      class="flex flex-no-wrap overflow-x-scroll bg-gray-100 shadow shadow-inner p-4"
    >
      <div
        class="flex-none bg-white rounded-lg mr-8 p-5 border border-gray-200"
        style="width: 400px"
      >
        <div class="text-center font-extrabold mb-4">
          {{ $t("step-4.items.information") }}
        </div>
        <registration-item
          name="name"
          :value="registration.firstname + ' ' + registration.lastname"
        ></registration-item>
        <registration-item
          name="email"
          :value="registration.email"
        ></registration-item>
        <registration-item
          name="phone.number"
          v-if="phoneNumber"
          :value="phoneNumber"
        ></registration-item>
        <registration-item
          v-if="isFieldAvailable(tour, 'extrafield_din_skier_sole_length')"
          name="sole-length"
          :value="registration.extrafield_din_skier_sole_length"
        ></registration-item>
        <registration-item
          v-if="isFieldAvailable(tour, 'extrafield_din_recommended')"
          name="recommended-din"
          :value="registration.extrafield_din_recommended"
        ></registration-item>
        <registration-item
          v-if="isFieldAvailable(tour, 'extrafield_din_selected')"
          name="selected-din"
          :value="registration.extrafield_din_selected"
        ></registration-item>
      </div>
      <div
        class="flex-none bg-white rounded-lg mr-8 p-5 border border-gray-200"
        style="width: 400px"
      >
        <div class="text-center font-extrabold mb-4">
          {{ $t("step-4.items.identity-card") }}
        </div>
        <img
          :src="registration.proofOfIdentities[0].image"
          style="max-height: 300px;"
          class="mx-auto"
        />
      </div>
      <div
        v-if="signatureField"
        class="flex-none bg-white rounded-lg p-5 border border-gray-200"
        style="width: 400px"
      >
        <div class="text-center font-extrabold mb-4">
          {{ $t("step-4.items.signature") }}
        </div>
        <img
          :src="registration.signature"
          style="max-height: 300px;"
          class="mx-auto"
        />
      </div>
    </div>

    <div class="mt-5 w-1/2 mx-auto">
      <label class="uppercase text-gray-400 text-left">{{
        $t("step-4.security-code")
      }}</label>
      <cleave
        type="password"
        v-model="password"
        :options="{ blocks: [4], numericOnly: true }"
        inputmode="decimal"
        class="transition focus:outline-0 border border-transparent focus:bg-white focus:border-gray-300 placeholder-gray-600 rounded-lg bg-gray-200 py-2 px-4 block w-full appearance-none leading-normal ds-input text-xl"
      />
    </div>

    <div v-if="loading" class="text-center pt-4">
      <spinner class="w-16 h-16 text-gray-400 stroke-current mx-auto"></spinner>
      <span class="text-gray-400 font-extrabold text-2xl">Loading....</span>
    </div>

    <template v-slot:footer>
      <button
        type="button"
        class="text-xl bg-blue-500 text-white rounded-lg py-4 px-4  w-1/2"
        :class="{
          'opacity-50 cursor-not-allowed': !isValidPassword || loading,
        }"
        :disabled="!isValidPassword || loading"
        v-on:click.prevent="registrationConfirmed()"
      >
        {{ $t("step-4.validate") }}
      </button>
    </template>
  </full-screen>
</template>
<script>
import { parsePhoneNumberFromString } from "libphonenumber-js/mobile";

import security from "../auth/security-code";
import { isFieldAvailable } from "../helpers/fields";

import Cleave from "vue-cleave-component";
import FullScreen from "@/components/FullScreen.vue";
import RegistrationItem from "@/components/RegistrationField.vue";
import Spinner from "@/components/Spinner.vue";


export default {
  components: {
    Cleave,
    FullScreen,
    RegistrationItem,
    Spinner,
  },
  props: {
    registration: {
      required: true,
    },
    loading: {
      default: false,
    },
    tour: {
      required: true,
    },
  },
  data() {
    return {
      password: null,
      isFieldAvailable:isFieldAvailable
    };
  },
  computed: {
    phoneNumber: function() {
      if (this.registration.phone && this.registration.phone_country) {
        const parsedPhoneNumber = parsePhoneNumberFromString(
          this.registration.phone,
          this.registration.phone_country
        );
        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          return parsedPhoneNumber.format("INTERNATIONAL");
        }
      }
      return undefined;
    },
    isValidPassword: function() {
      return security.isValid(this.password);
    },
    signatureField() {
      return this.tour.fields.find(field => field.type === 'signature')
    }
  },
  methods: {
    registrationConfirmed: function() {
      this.$emit("registrationConfirmed");
    },
  },
};
</script>
