<template>
    <transition name="custom-classes-transition" 
    enter-active-class="animated bounceInUp"
    leave-active-class="animated bounceOutDown"
    >
        <div v-if="show" class="fixed w-full h-full top-0 left-0 bg-white flex flex-col">
            <div class="h-8 p-8">
                <svg v-on:click.prevent="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-12 fill-current text-gray-600 float-right">
                    <path fill-rule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"/>
                </svg>
            </div>
            <slot class="max-w-xl  mx-auto max-h-full"></slot>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        show: {
            default : false,
        }
    }
}
</script>