<template>
  <div class="flex flex-no-wrap justify-center items-center w-full">
    <button
      class="bg-gray-200 focus:outline-none h-16  w-16 flex flex-no-wrap justify-center items-center rounded rounded-l-lg"
      v-on:click.prevent="decrease()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="w-8 icon-blue-500"
      >
        <circle cx="12" cy="12" r="10" class="primary" />
        <rect width="12" height="2" x="6" y="11" class="secondary" rx="1" />
      </svg>
    </button>
    <div
      class="w-24  h-16 flex content-center  items-center  text-center bg-white shadow-inner"
    >
      <div class="w-full text-center text-2xl font-extrabold">{{ value }}</div>
    </div>
    <button
      class="bg-gray-200 focus:outline-none  h-16 w-16 flex flex-no-wrap justify-center items-center rounded rounded-r-lg"
      v-on:click.prevent="increase()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        class="w-8 icon-blue-500"
      >
        <circle cx="12" cy="12" r="10" class="primary" />
        <path
          class="secondary"
          d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
        />
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  methods: {
    decrease() {
      if (this.value > 0.5) {
        this.$emit("input", this.value - 0.5);
      }
    },
    increase() {
      if (this.value < 12) {
        this.$emit("input", this.value + 0.5);
      }
    },
  },
  data() {
    return {};
  },
};
</script>
