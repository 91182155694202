<template>
    <canvas ref="canvas"></canvas>
</template>
<script>
    import QRCode from 'qrcode'
    export default {
        props : {
            text: {
                required: true,
            }
        },
        watch: {
            text : function(value) {
                this.createQrCode()
            }
        },
        methods: {
            createQrCode() {
                QRCode.toCanvas(this.$refs.canvas, this.text, function (error) {
                    if (error) console.error(error)
                })
            }
        },
        mounted() {
           this.createQrCode()
        }

    }
</script>